.rasdelrow{
    padding: 5px 3px;
}
tr.rasdelrow.delete{
    background-color: #edcabf !important;
}

.rasdelrow td{text-align: center;}
td.empty { text-align: center;  width:40px;}
td.sort { text-align: right; width:50px;}
td.sort div{padding-right: 15px;}
td.name, td.namearticle, td.elementname, td.email,td.nameicon ,td.nameuser,td.ip{ text-align: left;  width:20vw;}
td.namearticle div{cursor: pointer; text-decoration: underline;display: inline-block;
    }
td.ip{text-align: center;}
td.iconurl{text-align: left;  width:3vw; }
td.color { width: 100px; }
td.color div{border:1px solid cadetblue; font-size: 0.8rem; padding: 5px;}
td.parametr, td.nametag { text-align: left;  width:15vw;}
td.name div,td.parametr div, .td.name div{padding: 0 4px 0;}
td.nameurl { text-align: left; width:10vw;}
td.nameurl div{padding: 0 4px 0;}
td.elementurl{max-width: 10vw; font-size: smaller}
td.rol, td.rolarticle, td.rolelement{text-align: center;width:100px;}
td.redirect,td.icon{width:60px;}
td.vyvod{width:70px;}
td.value{width:5vw;}
td.note{text-align: left;max-width:18vw;}
td.value div,td.note div{padding: 0 4px 0;}
td.picture, td.comment{width:80px;}
td.button{width:143px;text-align: right;}

td.name input, td.namearticle input, td.elementname input,  td.nameurl input, td.parametr input, td.nametag input,
td.value input, td.note input, td.email input,td.nameicon input,td.iconurl input, td.nameuser input,td.ip input
{ text-align: left;  width:98%; font-size: 14px;margin:4px 0;}
td.name input:focus, td.nameurl input:focus,
td.parametr input:focus, td.value input:focus, td.note input:focus {border: 1px solid blue;}
td.value input,td.note input{ width:94%;}

td.rol select,td.rolarticle select, td.rolelement select{font-size: 14px; width:90%;padding: 0 6px 0;height:23px;}

