pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.articlebody{line-height: 1.6rem;word-spacing: 0.12rem; margin-bottom: 1.2rem; /*320px 768px 800 1000px*/
font-size: 1rem; flex: 2 0 40%;margin-left: 0.5rem;}
@media (max-width:660px){
    .articlebody{margin-bottom: 0.5rem;}
}
.articlebody h3{font-weight: normal}
.align-right {text-align:right;}
.align-left {text-align:left;}
.align-center {text-align:center;}
.align-justify {text-align:justify;}
pre{background-color: #DDD; margin: 3px 5px;  border-radius: 5px;padding:2px;}
hr{margin: 0 10px;}
.postotstup{ margin: 0 0 0 10%;}
.posthr{margin:15px 5%;}
.underline{text-decoration: underline;}
.strikethrough{text-decoration: line-through;}
.code {
    font-family: Inconsolata, Menlo, Monaco, Consolas, 'Courier New',    monospace;
    background-color: #e1e1e1; font-size: 16px;    padding: 2px
}
.link{color: #a3654b; font-style: italic; margin: 4px 0;text-decoration: underline dotted #a3654b;
    text-underline-offset: 4px;}
.linkinn{color: #1f95de; font-style: italic; text-decoration: underline dotted #1f95de;
    text-underline-offset: 4px; }

.redfont{color: rgba(255, 0, 0, 1.0);}
.greenfont{color: rgba(21, 125, 21, 1.0);}
.bluefont{color: rgba(0, 0, 200, 1.0);}
.blueback{color: rgba(255, 255, 255, 1.0);background-color: rgba(0, 0, 255, 1.0);}
.yellowback{color: rgba(0, 0, 0, 1.0);background-color: rgba(255, 239, 0, 1.0);}
.redback{color: rgba(255, 255, 255, 1.0);background-color: rgba(255, 0, 0, 1.0);}

.image{width:85%;} /* переменный размер */
 img{max-width:100%;/*margin: 3px 10px;*/}
 .flexforimage{display: flex;   flex-direction: row;width:100%;}
 .imagemain{ /*display: flex; flex-direction: column; width: 100%;*/
     display: grid;   grid-template-columns: 20px  1fr 20px;   grid-template-rows:  1fr auto 0; align-items: end;
     padding:10px;  position:relative; align-self: center;
     background: var(--mainfoncolor); box-shadow: -1px -2px 10px #C5D5E6, 3px 2px 8px rgba(134, 179, 219, 0.4);
     border-radius: 16px; margin-bottom: 15px;
     max-width:100% ;
     min-width: fit-content;
 }
.imagemain picture{max-width:100%; /*clip-path: inset(0%,100%, 0, 100%, round 10px);*/   grid-column: 1 / -1;   grid-row: 1 / -3; line-height: 0; }
.imagemain picture img{max-height: 80vh; border-radius:10px;}
 .noteimage{ margin-top: 5px; font-style: italic ;width: min-content;  min-width: fit-content;
grid-column: 2 / -1; grid-row: 2/ -2; position: relative; font-size: 0.95rem;
    color:var(--maintextcolor);
 }
 .emptynote{height:8px;}

.archive, .pdf, .mp3{margin-left: 10px;}
.archive a, .pdf a, .mp3 a{color: #62520b; text-decoration:  none;}
.archive a:hover, .pdf a:hover, .mp3 a:hover{text-decoration: underline}
.archive .icon{background-image:url('../../../images/icons/zip.png');
    width:20px; height:20px; background-size: cover; margin-right: 10px;
    display:inline-block;  vertical-align:bottom;}
.pdf .icon{background-image:url('../../../images/icons/pdf.png');
    width:20px; height:20px; background-size: cover; margin-right: 10px;
    display:inline-block;  vertical-align:bottom;}
.mp3 .icon{background-image:url('../../../images/icons/musical-note.png');
    width:20px; height:20px; background-size: cover; margin-right: 10px;
    display:inline-block;  vertical-align:bottom;}
.polbloka16{width:70%; margin: auto;}
.polbloka4{width:60%; margin: auto;}
.videoWrapper16 {position: relative;
     padding-bottom: 56.25%; /* 16:9 */
    height: 0;  overflow: hidden;
}
.videoWrapper4 {position: relative;padding-bottom: 80%; height: 0;  overflow: hidden;}
.videoWrapper16 iframe, .videoWrapper4 iframe {
    position: absolute;    top: 0;    left: 0;    width: 100%;    height: 100%;
}
.pdf, .archive{margin:10px 0;}

@media (max-width:660px){
.polbloka16{width:90%; margin: auto;}
.polbloka4{width:80%; margin: auto;}
}
