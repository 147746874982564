.Header{
    display: block;
    font-weight: bold;
    width:40% ;
}
.Header .head{
    display: block;
    margin: 20px 0 0 20px;
    font-size: 20px;
    font-weight: normal;
}
.Header .user{
    display: inline-block;
    margin: 5px 10px 0 20px;
    font-size: 14px;
    font-weight: normal;
}