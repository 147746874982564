.headerasdel{
background: #d3d3d3;
}
.headerasdel th{
padding: 4px 0;
}
.headerasdel .stinky, .headerasdel .article, .headerasdel .element{
    position: -webkit-sticky;
    position: sticky;
    top: -3px; /*// при нуле в chrome остаётся странный зазор*/
z-index: 2;
}
.empty{ width:40px;}
.sort{width:50px;}
.stopip{width:100px;}
.name{width:25vw;}
.email{width:25vw;}
.password{width:300px;}
.namearticle{width:25vw;}
.elementname{width:20vw;}
.nameurl{width:10vw;}
.elementurl{width:100px;}
.rol{width:80px;}
.rolelement{width:80px;}
.redirect{width:60px;}
.vyvod{width:70px;}
.picture, .options, .comment{width:80px;}
.button{width:100px;}
.parametr{width:15vw;}
.value{width:150px;}
.note{width:18vw; max-width: 20vw;}
.value{width:5vw;}


.article{
    background: #d8e4e7;
}
.element{
    background: #d0d6e7;
}