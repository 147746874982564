.headercomment{text-align: left;margin:0 5px 10px 0; padding-left: 5px; font-size: 1.1rem; background-color: var(--commentcolorfon); color: #7d7d7d}
.listcom{margin-left: 2rem;} /* for860 == 0 */
.block-comm{ width: 30vw; background-color: #ebf5fc; margin:2px 5px; }
.comment{display:flex;flex-direction:row; margin-bottom:0.7rem;}
.comment:hover {background-color: #cae8ff;}
.otstupcomm{margin-left:1.5rem; margin-right:2px;}
.admin{background-color: #b3e0ff5e; border-radius: 12px;}
.logoshort{width:2rem; height:2rem;background-color:#ebf5fc;flex-shrink: 0;  font-size:0.8rem; margin: 2px 5px 0 0;
    display: inline-flex;  justify-content: center;  align-items: center;border-radius: 50%; padding: 4px 1px 2px 2px;
    border: 1px solid #918bd9;}
.admin .logoshort{font-size:1.1rem; color:#fff;font-weight:500; margin:2px 5px 0 2px;}
.nametext{display:flex;flex-direction:column;margin-left:3px;width: 100%;}

.namedate{}
.namecomm{margin:0 5px 0 0;font-size:0.8rem;}
.datecomm{margin:0 0 0 5px;font-size:0.8rem;}
.textreply{display:flex;flex-direction:column;margin-top:3px;}
.textcomm{white-space: pre-wrap;}
.replycomm{width:1.7rem; height:1.7rem;cursor: pointer; border-radius: 30%; margin-top:3px; margin-left: auto;;
    background: url('../../../images/icons/reply.svg') no-repeat ;
    background-size: cover;}
.graycomm{color: #b3a5a5}

@media (max-width:660px) {
    .replycomm{margin-bottom: 1rem; }
}
/*
.replycomm{pointer-events: auto}
.nametext{pointer-events: none}
.nametext:hover {background-color: #cae8ff;}*/
