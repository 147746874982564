:root {
    --headerheight:74px;
    --maintextcolor:#79785C;
    --proportmenu:8%;
    --mainfoncolor:#ebf5fc; /* #E3EDF7 */
    --skeleton:#D9EDFBE3;
    --asidesize:20%;
    --commentcolorfon: #d0d0d0;
    --calcfirst: #e3f2fd;
    --calcsecond: #c5e5ffc4;
    --colortag: rgba(38, 79, 157, 0.77);
     /* asideup, Sideselect  yet media*/
}
html {font-size: 16px;}
*, *:after, *:before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    /**/
    /* добавим плавность переходов для всех элементов страницы*/
}
@media (max-width:480px){html {font-size: 15px;}}
@media (max-width:330px){html {font-size: 15px;}}

.underbody{background: var(--mainfoncolor);
    background: linear-gradient(90deg, rgba(192, 229, 255, 0.85) 0%, rgb(235, 245, 252)
    10%, rgb(235, 245, 252) 90%, rgb(192, 229, 255, 0.85) 100%)
}
 /*rgb(209, 220, 227)*/

header:after, .container:after, footer:after, .widget-posts-list li:after, #subscribe:after {
    content: "";
    display: table;
    clear: both;
}
body {
    font-family: 'Montserrat', arial, sans-serif;
    line-height: 1.2;
    color: #373737;
    background: #f7f7f7;   /* background-color: #cacece; */
    min-height: 97vh;
}
.scrollfix{position: absolute;     /* Вся магия в этой строчке ;-) */    width: calc(100vw - 100%);}
.container{
    margin: 0 auto;     width: 100%;
    max-width: 960px; min-height: 100vh;
    display: flex; flex-direction: column;
    transition: .5s ease-in-out; background: var(--mainfoncolor);
}
.container img{margin: 0;}
.placesearch{max-width: 960px;   display: block;    position: relative;}
.mainpart{
      flex: 1;
    display: grid; grid-template-columns: 70% 30%; grid-template-rows: auto auto auto;
    background: var(--mainfoncolor);
}

.main{ background: var(--mainfoncolor); min-height: 85vh;
    /*flex-grow:3; flex-shrink:1; flex-basis:calc(100% - var(--asidesize));*/
    grid-column: span 1; grid-row: 1/ -1;
    /*display: flex;!!!!!!!!!!!!!!!!!*/ flex-direction:column; padding: 10px; gap:10px;
}
@media (max-width:900px){
    .mainpart{grid-template-columns: 75% 25%;}
}

@media (max-width:800px){
    .mainpart{grid-template-columns: 100%; } /*grid-template-rows: 100% auto auto;*/
    .main{grid-column: span 1; grid-row: 1/ 2;}
}
.mainheader {display: flex; justify-content: center; align-items: center;
    width: 100%; height: 47px; background: var(--mainfoncolor); box-shadow: -3px -3px 7px #FFFFFF, 3px 3px 7px rgba(136, 165, 191, 0.48);
    border-radius: 8px;
    position: sticky;
    top: 81px; z-index: 7;
    transition: 0.5s; color:var(--maintextcolor) ;
}
.hidslit{height: 25px;background-color: var(--mainfoncolor); margin: -20px 0;position: sticky; top: 66px; z-index: 7;
    transition: .5s ease-in-out;}
/*.aside{background: var(--mainfoncolor); flex: 1 1 var(--asidesize); display:flex;flex-direction:column; padding: 10px 10px 10px 0; gap:10px;}*/
.header_hidden{ /*transform: translateY(-82px);*/ top:1px;}
.hidslit_hidden{ /*transform: translateY(-82px);*/ top:25px;}
