.sets{
    width:100%;
    margin-top:9px;
    margin-left: 10px;
    color: #5e5e5e;
    border-spacing: 0;
    font-weight: normal;
}
.sets tr:nth-child(2n) {
    background: #ebebeb; /* Цвет фона */
}
.sets th { /*:nth-child(1)*/
    background: #d3d3d3;
}
.scrolrasdel{

}