.commblock{ border:0px solid #d7dffd;padding:4px; background-color: #ebf5fc; }

.comlog{display: flex;align-items: center}
.comlog label{margin-right: 3px;color: #7d7d7d}
.namestore{margin: 0 15px; color:var(--fontcolorselect);font-size: 1.2rem; }
.errormsg{font-size: 0.7rem;margin-left: auto;color: #7d7d7d; margin-right: 10px;}
.buttonlog{
    width:auto; height: 30px;     margin:0 2px; background-color: var(--commentcolorfon); border-radius: 2px;   border:none; padding: 3px 6px;}
.buttonlog:hover{cursor: pointer;
    border: 1px solid #6c6c6c;     box-shadow: 1px 1px 3px 0 rgba(50, 50, 50, 0.75);
}
.inputlog{height: 1.8rem;background-color: var(--mainfoncolor);font-size: 1.2rem; width: 30%;outline:none;}
.errorlog{background-color: rgba(255, 7, 23, 0.24);}

.addcomment{display: flex; margin-top: 8px; position: relative;align-items: flex-start;}
.comerror{font-size: 0.8rem;color: #8f0f0e; margin: 8px 0 0 12px;
}
.hider{display: none;}
.addtextarea{border: 1px solid #ddd; border-radius: 8px;  background-color: transparent;  padding: 5px;width: 100%;min-height: 60px;
    line-height: 1.2;resize: none;font-size: 1.1rem;outline:none;}
.emojiicon{width:30px; height: 30px; font-size: 1.4rem; cursor: pointer;margin-left: 10px;}
.emojiblock{z-index: 1000;
    position: absolute !important;
    right: -7px; height: 30vh;
    top: 30px;}

.addbuttonblock{display: flex; }
.addbutton{border-radius: 8px;margin-left: 10px; font-size: 1.2rem; cursor: pointer; color: var(--fontcolorselect);
    padding: 10px 20px;    border: 2px solid var(--commentcolorfon);   background-color: var(--commentcolorfon);}
.addbutton:hover{box-shadow: 1px 1px 3px 0 rgba(50, 50, 50, 0.75);}

.showanswer{ width: 2.2rem; height: 2.2rem; border: none; cursor: pointer;
    background: url('../../../images/icons/comment.png') no-repeat ;    background-size: cover;}



.emojiicon2{
    background: url('../../../images/icons/icons-save.png') no-repeat ;
    background-size: cover;
}

.deletelogin{
    background: url('../../../images/icons/icons-cancel.png') no-repeat ;
    background-size: cover;
}

@media (max-width:660px) {
    .addcomment{flex-wrap: wrap; gap: 10px; order:2}
    .emojiicon{width:60px; height: 60px; padding: 10px; margin: 0; order: 1}
}
@media (max-width:400px) {
    .inputlog {width: 60%   }
    .buttonlog{height: 3rem}
}