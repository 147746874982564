.rasdel{
   width:98%;
    margin-left: 10px;
    color: #5e5e5e;
    border-spacing: 0;
    font-weight: normal;
}
.rasdel tr:nth-child(2n) {
    background: #ebebeb; /* Цвет фона */
}
.rasdel th { /*:nth-child(1)*/
   /* background: #d3d3d3;*/
}
.scrolrasdel{

}
.article, .element{margin-top: 2px;}
.articlename{margin:20px 0 2px 15px; font-size: medium;color: #5e5e5e;}