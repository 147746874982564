.buttonpict{
    width:30px; height: 30px;
    margin:0 4px;
    border:none;

}
.buttonpict:hover{
    border: 1px solid #6c6c6c;
    box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.75);
}

.class-true{
    width:25px; height: 25px;
    background: url('../../../images/icons/icon-yes.png') no-repeat ;
    background-size: cover;
}
.buttonpict.close{
    width:25px; height: 25px;
    background: url('../../../images/icons/icons-delete.png') no-repeat ;
    background-size: cover;
}
.buttonpict.refresh{
    width:25px; height: 25px;
    background: url('../../../images/icons/refresh.png') no-repeat ;
    background-size: cover;
}
.buttonpict.directory{
    width:25px; height: 25px;
    background: url('../../../images/icons/folder.png') no-repeat ;
    background-size: cover;
}
.buttonpict.orderup{
    width:25px; height: 25px;
    background: url('../../../images/icons/orderup.png') no-repeat ;
    background-size: cover;
}
.buttonpict.closeround{
    width:25px; height: 25px;
    background: url('../../../images/icons/close-round.png') no-repeat ;
    background-size: cover;
}
.buttonpict.markdelete{
    width:25px; height: 25px;
    background: url('../../../images/icons/icons-markdelete.png') no-repeat ;
    background-size: cover;
}
.buttonpict.delete{
    width:25px; height: 25px;
    background: url('../../../images/icons/icons-delete.png') no-repeat ;
    background-size: cover;
}
.buttonpict.cropper{
    width:25px; height: 25px;
    background: url('../../../images/icons/crop.png') no-repeat ;
    background-size: cover;
}
.buttonpict.tag{
    width:25px; height: 25px;
    background: url('../../../images/icons/tag.png') no-repeat ;
    background-size: cover;
}
.buttonpict.cancel, .buttonpict.closenote{
    width:25px; height: 25px;
    background: url('../../../images/icons/icons-cancel.png') no-repeat ;
    background-size: cover;
}
.buttonpict.save, .buttonpict.savenote{
    width:25px; height: 25px;
    background: url('../../../images/icons/icons-save.png') no-repeat ;
    background-size: cover;
}
.buttonpict.note{
     width:25px; height: 25px;
     background: url('../../../images/icons/icons-edit.png') no-repeat ;
     background-size: cover;
 }
.buttonpict.delete{
    width:25px; height: 25px;
    background: url('../../../images/icons/icons-delete.png') no-repeat ;
    background-size: cover;
}
.buttonpict.bold{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-bold.png') no-repeat center/80% ;
}
.buttonpict.italic{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-italic.png') no-repeat center/80% ;
}
.buttonpict.underline{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-under.png') no-repeat center/80% ;
}
.buttonpict.strikethrough{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-strike.png') no-repeat center/80% ;
}
.buttonpict.code{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-code.png') no-repeat center/80% ;
}
.buttonpict.align-left{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-align-left.png') no-repeat center/80% ;
}
.buttonpict.align-right{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-align-right.png') no-repeat center/80% ;
}
.buttonpict.align-center{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-align-center.png') no-repeat center/80% ;
}
.buttonpict.align-justify{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-align-justified.png') no-repeat center/80% ;
}
.buttonpict.addlink{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-link.png') no-repeat center/80% ;
}
.buttonpict.replaceQuotes{
    width:25px; height: 25px;
    background: url('../../../images/icons/chevron.png') no-repeat center/80% ;
}
.buttonpict.undo{
    width:25px; height: 25px;
    background: url('../../../images/icons/undo.png') no-repeat center/80% ;
}
.buttonpict.redo{
    width:25px; height: 25px;
    background: url('../../../images/icons/redo.png') no-repeat center/80% ;
}
.buttonpict.export{
    width:25px; height: 25px;
    background: url('../../../images/icons/export.png') no-repeat center/80% ;
}
.buttonpict.import{
    width:25px; height: 25px;
    background: url('../../../images/icons/import.png') no-repeat center/80% ;
}
.buttonpict.hrline{
    width:25px; height: 25px;
    background: url('../../../images/icons/style-hr.png') no-repeat center/80% ;
}
.buttonpict.uparrow{
    width:25px; height: 25px;
    background: url('../../../images/icons/sortup.png') no-repeat center/80% ;
}
.buttonpict.downarrow{
    width:25px; height: 25px;
    background: url('../../../images/icons/sortdown.png') no-repeat center/80% ;
}
.buttonpict.yes{
    width:25px; height: 25px;
    background: url('../../../images/icons/icon-yes.png') no-repeat center/80% ;
}
.buttonpict.google{
    width:25px; height: 25px;
    background: url('../../../images/icons/google-drive.png') no-repeat center/80% ;
}
.buttonpict.youtube{
    width:25px; height: 25px;
    background: url('../../../images/icons/youtube.png') no-repeat center/80% ;
}
.buttonpict.nochecked{
    width:25px; height: 25px;
    background: url('../../../images/icons/icons-nochecked.png') no-repeat center/80% ;
}
.buttonpict.checked{
    width:25px; height: 25px;
    background: url('../../../images/icons/icons-checked.png') no-repeat center/80% ;
}
