.tagsauto{display: flex; flex-direction: row;}
.suggestions {
    position: absolute;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    z-index: 100;
    background-color: white;
}
.ulblock{margin-left: 5px;}
.suggestionactive {
    background-color: #ccc9d1;
}
ul.suggestions {padding: 0; margin: 0;min-width: 200px;}
.suggestions > li {
    list-style: none;
    display: block;
    text-align: left;
    line-height: 1.5;
    font-size: 0.9rem;
    padding: 0 5px ;
}