.mainmenu{
    display: inline-block;
    width: 100%;
    list-style: none;
    margin: 3px 0 3px 10px;
    padding: 4px 0;
    background: #e8e8e8;
    border-radius: 4px;
    font-size: 13px;
}
.mainmenu ul, .mainmenu li{
    display: inline;
}
.mainmenu ul{
    padding-left: 10px;
}
.mainmenu li{
    margin: 5px 5px 0 2px;
    padding:3px 0 3px 5px;
    color: #3b3b3b;
    text-transform: uppercase  ;
}
.mainmenu li a{color: #3b3b3b; text-decoration-line: none;}
.mainmenu li a:hover{color: #7d7d7d;}
.active{
    font-weight: bold; cursor: pointer;
    color: rgb(100,100,110)
}