.buttoncom{
    width:35px; height: 35px;
    margin:0 4px;
    border:none;
}
.buttoncom:hover{
    border: 1px solid #6c6c6c;
    box-shadow: 1px 1px 3px 0 rgba(50, 50, 50, 0.75);

}
.saverecord{
    background: url('../../../images/icons/icons-save.png') no-repeat ;
    background-size: cover;
}
.editrecord{
    background: url('../../../images/icons/icons-edit.png') no-repeat ;
    background-size: cover;
}
.deleterecord{
    background: url('../../../images/icons/icons-delete.png') no-repeat ;
    background-size: cover;
}
.markdeleterecord {
    background: url('../../../images/icons/icons-markdelete.png') no-repeat;
    background-size: cover;
}
.canceledit{
    background: url('../../../images/icons/icons-cancel.png') no-repeat ;
    background-size: cover;
}
.switchoff {
    background: url('../../../images/icons/switchoff.png') no-repeat;
    background-size: cover;
}
.switchon {
     background: url('../../../images/icons/switchon.png') no-repeat;
     background-size: cover;
 }
.sortleft {
    background: url('../../../images/icons/sortleft.png') no-repeat;
    background-size: cover;
}
.sortright {
    background: url('../../../images/icons/sortright.png') no-repeat;
    background-size: cover;
}