:root {

   /* --mainfoncolor:#ebf5fc; !* #E3EDF7 *!
    --maintextcolor:#79785C;
    --proportmenu:8%;*/
}
body{
    overflow-y: scroll;
}
.headerup{ display:flex; justify-content:space-between;height: var(--headerheight);overflow-y: hidden;
    position: sticky; z-index: 10;left: 0;top: 0;transition: 0.5s;width: 100%;
}
.header{
    display: flex; flex-direction: row; align-items: flex-start; z-index: 10; position: relative;
    padding: 0; width: 100%; overflow-x: auto; overflow-y:hidden;
    height: calc(var(--headerheight) + 15px);padding-bottom: 15px;
    color:var(--maintextcolor);
    background: var(--mainfoncolor);box-shadow: 0 4px 8px rgba(136, 165, 191, 0.42);
    top: 0;

}
@media (max-width:660px){
   /* .header{ width:calc(var(--asidesize) * 6);        }*/
}

.logo{ display: flex; flex-direction: row; justify-content: left; align-items: stretch;min-width: 68px;
    width: calc(var(--asidesize) * 0.4);   height: 100%; left: 0; top: 0; background: var(--mainfoncolor);
}
@media (max-width:660px){
    .logo{
    }
}
.logoback{ width:80%; height: 80%; margin: auto;}
.logoback svg{width:100%; height:100%}
.asideheader{width:var(--asidesize);padding:0 20px 0 5px; height: 100%; display: flex; align-content: center;
    justify-content: right;}
.tlglogo{display: flex; margin-right: 1rem;  align-items: center;  }

.buttonpanel {width: 40px; height: 40px; margin-left:5px; border: none;  cursor:pointer; padding: 6px;
     box-shadow: -4px -2px 6px #FFFFFF, 4px 2px 10px rgba(136, 165, 191, 0.48);
    border-radius: 8px;transition: .5s; }
.buttonpanel:hover, .buttonpanel.active{  background-size: auto;
    box-shadow: inset -3px -3px 10px #FFFFFF, inset 3px 3px 10px rgba(136, 165, 191, 0.48);
    border-radius: 8px;}

.headernav{display: flex; flex-direction: row; align-items: flex-start; padding: 15px 0px 12px; flex: 1 1 auto;
    width: calc(var(--asidesize) * 3.5); height: 100%;  width: fit-content;
    background: var(--mainfoncolor); font-size: 0.9rem;font-weight: 400; text-transform: uppercase;
}
@media (max-width:800px){
    .headernav{/*width: calc(var(--asidesize) * 3.8);*/ }
    .asideheader{justify-content: center;}
}
@media (max-width:660px){
    .asideheader{width: auto;}
    .headernav{margin-left: 0.5rem;}
}

ul.menu{display: flex; flex-wrap: nowrap;width: 100%; height:100%; gap:10px;  list-style: none;}
.menu li {    margin-right: 0; /*max-width:var(--menupunkt);*/  flex-grow: 1; min-width: 100px;}

.lipunkt{display: flex; flex-direction: row; align-items: center; width: max-content; }

@media (max-width:480px){
    .asideheader{width: auto;}
    .headernav{margin-left: 0.5rem; }
    .lipunkt{min-width: 88px;}
}
@media (max-width:330px){

}

.scrollelement{width: 70px;padding:0 5px; display: flex; height: var(--headerheight);; background:var(--mainfoncolor);
    align-content: center;justify-content: center;box-shadow: -3px 0px 6px rgba(136, 165, 191, 0.42);    z-index: 12;}
.buttscrollelement {width: 90%; height: 90%; margin: auto; border: none;  cursor:pointer;
    background: center no-repeat url('../../../images/icons/scrolright.png') ;
    background-size: 50%;
    border-radius: 8px;transition: .5s; }
.scrollelement .left{background: center no-repeat url('../../../images/icons/scrolleft.png') ;
    background-size: 50%; }
.over, .overactive{
    display: flex;
    flex-direction: row; justify-content: center; align-items: stretch;  gap: 5px; /*max-width:30%;*/
    height: 100%; width:100%;
    background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), var(--mainfoncolor);
    background-blend-mode: soft-light, normal;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 4px rgba(136, 165, 191, 0.42), -3px -3px 10px rgba(250, 251, 255, 0.84);
    border-radius: 7px;
    transition: all .5s ease-in;
    color: var(--maintextcolor);text-decoration:none;
}
.overtext{font-style: normal;
    line-height: 22px; padding: 0 5px; margin-right: 10px;
    display: flex; align-items: center; text-align: center;
    white-space: nowrap; overflow: hidden;     text-overflow: ellipsis;
    transition: all .5s ease-in;
}
.overicon{align-self: center; margin-top:3px;margin-left: 5px;}
.overicon img{height:1.2rem;}
.over:hover, .overactive {   box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.25), inset -2px -2px 4px rgba(255, 255, 255, 0.67);
    color: var(--maintextcolor);}
.header_hidden{ transform: translateY(-100%);}

@media (max-width:480px){
    .overicon{display: none;}
    .overtext{margin-right:0}
    .scrollelement{width: 50px;}
}