.blockaddpicture{width:87%;border:2px solid #c2c2c2;}
.fonbutton{background-color: #dbdbdb;margin:-1px 0 0px;}
.fonbutton form{display: flex; flex-direction: row; justify-content: flex-start;}
.label, .imgmain{
    margin:1px 10px; border:none;
    width:25px; height:25px;
    display:block;
    cursor:pointer;text-align:center;
}
.label{
    background: url('../../../images/icons/icons-photo.png') no-repeat ;
    background-size: cover;}

.fileinput{ outline:0;opacity:0;pointer-events:none;user-select:none}
.imgpreview{width:60%}
.note{width:100%;height:1.3em;font-size: 1em;}
.noteinput{width:100%; height:3rem; font-size: 1.2em;}
.imagealign{ display: flex; flex-direction: row;}