.sidebar{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 3px 0 0 12px;
    /*width:20vw*/;
    border:1px solid #c9c9c9;
    border-radius: 5px ;
}
.sidebar .header{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-start;
    background-color: #c9c9c9;
    border-radius: 5px 5px 0  0;
}
.sidebar .headerdiv{
    display: inline-block;
    margin-left:15px;
    margin-right: auto;
    width:100%;
    font-size: 14px;
}
.sidebar .body{
    margin-top: 10px;
}