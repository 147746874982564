.flex{display:flex;flex-direction:column; justify-items:center;
    color: #a0d2e1;
    font-size: 3rem;
    font-family: 'Fira Mono', monospace;
    letter-spacing: -7px; margin-top: 2rem;}
.block404{align-self:center; width:fit-content;}

.text {font-size:2rem; letter-spacing:1px;}

.block404{ animation: glitch 1s linear infinite;letter-spacing:1px}

@keyframes glitch {
    2% {
        transform: translate(2px, 0) skew(0deg)
    }
    4% {

    }
}
@keyframes glitch {
    2%,64%{
     transform: translate(2px,0) skew(0deg);
   }
  4%, 60%{
    transform: translate(-2px,0) skew(0deg);
    }
  62%{
   transform: translate(0,0) skew(5deg);
   }
}

.block404:before,
.block404:after{
    content: attr(title);
    position: absolute;
    left: 0;
}

.block404:before{
    animation: glitchTop 1s linear infinite;
    clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
    }
  4%,60%{
    transform: translate(-2px,2px);
    }
  62%{
    transform: translate(13px,-1px) skew(-13deg);
    }
}

.block404:after{
    animation: glitchBotom 1.5s linear infinite;
    clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom{
    2%,64%{
    transform: translate(-2px,0);
    }
    4%,60%{
     transform: translate(-2px,0);
    }
    62%{
    transform: translate(-22px,5px) skew(21deg);
    }
}