.tipblock{
    position: absolute;  background-color: #dbdbdb;    border-radius: 4px;

    }
.tipblock::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #b8b8b8 transparent;
}