
.aside{grid-column: span 1; grid-row: 1/ 3;}

.main{
    /*display:flex; width: 100%; min-height: max(calc(100vh / 2 - 20%),30vh); */
    margin:5px; padding: 5px; text-align: right;

}
.mainlist, .main:hover{
    background: var(--mainfoncolor); box-shadow: -3px -3px 7px #FFFFFF, 3px 3px 3px rgba(136, 165, 191, 0.48);
    border-radius: 8px;
}

@media (max-width:800px) {
    .aside{grid-column: 1 ; grid-row: 2/ 3;min-height:10vh;}
    .mainlist, .main:hover{border: none;box-shadow: none;}
    .main{padding: 0 5px;margin:0 5px;}
}