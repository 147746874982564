.sorter{
    border: 1px solid #b8b8b8;
    border-radius: 3px;
    display: block;
    padding: 0 5px;
    /*width:120px;*/
    height: 3em;
}


.sortbutton{
    width:30px; height: 30px;
    margin:8px 0 0 8px;
    border:none;
}
.sortbutton:hover{
    border: 1px solid #6c6c6c;
    box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.75);

}

.sortup{
    background: url('../../../images/icons/sortup.png') no-repeat ;
    background-size: cover;
}
.sortdown{
    background: url('../../../images/icons/sortdown.png') no-repeat ;
    background-size: cover;
}
.asc{
    background: url('../../../images/icons/icons-ascend.png') no-repeat ;
    background-size: cover;
}
 .desc{
    background: url('../../../images/icons/icons-descend.png') no-repeat ;
    background-size: cover;
}
.list{
    background: url('../../../images/icons/list.png') no-repeat ;
    background-size: cover;
}