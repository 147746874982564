
.search{height:100%;display: flex; align-items: center; justify-content: right;}
.buttonpanel {width: 40px; height: 40px; margin-left:5px; border: none;  cursor:pointer;
    background: center no-repeat url('../../../images/icons/lupa.svg') #E3EDF7;
    background-size: 50%; box-shadow: -4px -2px 6px #FFFFFF, 4px 2px 10px rgba(136, 165, 191, 0.48);
    border-radius: 8px;transition: .5s; }
.buttonpanel:hover, .buttonpanel.active{  background-size: auto;
    box-shadow: inset -3px -3px 10px #FFFFFF, inset 3px 3px 10px rgba(136, 165, 191, 0.48);
    border-radius: 8px;}
.off{background:#E3EDF7; }
.panel{position: absolute; display:flex;flex-direction:column; align-items: center; right:3px;top:0; padding: 0 0 10px 0; opacity: 0;
    visibility: hidden; transition: visibility 0.5s, opacity 0.5s ease-in-out;z-index: 18;
    background: var(--mainfoncolor); box-shadow: -3px -3px 7px #FFFFFF, 3px 3px 7px rgba(136, 165, 191, 0.48);
    border-radius: 8px;     width: max(37%,355px);font-size: 16px;
}
@media (max-width:480px){
    .panel{ width: 100%;}
}
.panelon{visibility: visible; opacity: 1;margin-right:2px; transition: visible 0.5s, opacity 0.5s ease-in, margin-top 0.3s;z-index: 18;}

.clicker{ position: relative;  display: flex; width:90%; justify-content: stretch;}
.clicker input{position: absolute;     left: 0;     opacity: 0;}
.clicker label{ border: 1px solid red; width: 100%; text-align: center}
.clicker a{border: 1px solid #333;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), inset 0 1px 1px rgba(255, 255, 255, 0.45);
    background-color: #70c66b;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0));
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 1;
    width: 10px;
    height: 100%;display: block;
    transition: all 0.2s ease-out;
}
.calcscreen {
    position: relative; width: 90%;
    min-height: 62px;
    margin: 0 5px 6px 5px;
    padding: 0 8px;
    border-radius: 5px;
    box-shadow: inset 0 30px 1px rgba(255, 255, 255, 0.25), inset 0 -1px 2px rgba(255, 255, 255, 0.5), inset 0 2px 3px rgba( 0, 0, 0, 0.40);
    font-size: 1.4rem;
    line-height: 61px;
    text-align: right;
    white-space: nowrap;
    color: #333;
    cursor: default;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.01);
    background: #edefe0;
    background: -moz-linear-gradient(top, var(--calcfirst) 45%, var(--calcsecond) 80%);
    /*background: -webkit-gradient(linear, left top, left bottom, color-stop(45%,var(--calcfirst)), color-stop(80%,var(--calcsecond)));*/
    background: -webkit-linear-gradient(top, var(--calcfirst) 45%,var(--calcsecond) 80%);
    background: -o-linear-gradient(top, var(--calcfirst) 45%,var(--calcsecond) 80%);
    background: -ms-linear-gradient(top, var(--calcfirst) 45%,var(--calcsecond) 80%);
    background: linear-gradient(to bottom, var(--calcfirst) 45%,var(--calcsecond) 80%);
   /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dae1ef', endColorstr='#8facd3',GradientType=0 );*/
}
.blockbuttons{width: 90%; margin: 10px 0 0; display: flex; justify-content: center; flex-direction: column;align-items: center}
.switchblock{display: flex; justify-content: space-between; width: 90%; }
.range{display: flex; flex-direction: column;}
.rangetxt{font-size: 0.7rem;display: flex; justify-content: space-between;}
.range input[type='range'] { -webkit-appearance: none;-moz-appearance:none; height:25px; width: 80px;
    border-radius: 18px; background: transparent;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset, 0 0 4px rgba(0, 0, 0, 0.5) inset, 0 2px 2px 1px rgba(0, 0, 0, 0.3) inset;
}
.range input[type='range']::-webkit-slider-thumb,
.range input[type='range']::-moz-range-thumb
{-webkit-appearance: none !important; -moz-appearance:none; height:23px; width:23px;border-radius: 50%;
    background: var(--calcfirst);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1) inset, 0px 0 2px rgba(0, 0, 0, 0.2) inset, 0 1px 1px 0px rgba(0, 0, 0, 0.1) inset;
    transition: background-color 0.2s linear 0s;
}
.range input[type='range']::after{
    content: ""; top: 1px;
    position: absolute;
    box-shadow: 0 0 1px 1px #f4f4f4 inset, 0 0 3px 1px rgba(0, 0, 0, 0.6);
    background: linear-gradient(#D0D0D0, #FDFDFD) repeat scroll 0 0 rgba(0, 0, 0, 0);}

.buttoncalc{ width:50px;height:33px; margin-left:10px ;
    border-radius: 7px;
    border: 1px solid #D7E9FB;
    background: linear-gradient(135deg, rgba(206, 232, 255, 0.40) 4.29%, rgba(225, 236, 246, 0.40) 95.6%), linear-gradient(323deg, #C8D4E0 12.93%, #E0EBF6 105.76%);
    box-shadow: -1px 0 7px 0 #C3DEF9 inset, -2px 1px 12px 0 #C3D3E2 inset;
}
.buttoncalc:hover{
    background: linear-gradient(135deg, rgba(51, 53, 55, 0.40) 4.29%, rgba(225, 236, 246, 0.40) 12.38%), linear-gradient(323deg, #DBE6F1 12.93%, #FFF 105.76%);
    box-shadow: 5px 5px 10px 0px #B2C9E0 inset, -4px -5px 10px 0px rgba(213, 230, 245, 0.40) inset;
}
.selectarea{
    height: 200px; width:100%; background:var(--mainfoncolor); border: 1px inset #b9cedf;
   /* box-shadow:inset -1px -1px 2px 0 rgba(0,0,0,.5), inset 1px 1px 1px 2px rgba(255,255,255,0.5);*/
    margin:10px 0; padding: 5px 5px;
}
.endsearch{display: flex;justify-content: right;width: 100%;}
.endsearch button{margin:0 10px; }

.inputblog{font-family : Courier New, monospace; display: flex; flex-direction: column; justify-content: left; align-items: flex-start;
font-size: 0.75em; margin: 5px 0 0 0; width: 100%;}
.zagtxt{width: 100%; text-align: left; line-height: normal; display: flex;min-height: 24px;}
.aligntxt{width: 60px; text-align: right; flex-basis:60px;padding-right: 3px;}

@media (max-width:480px){
    .inputblog{font-size: 0.75em;}
}
@media (max-width:330px){
    .inputblog{font-size: 0.8em;}
}

.displaykey{display: flex; flex-wrap: wrap; align-content: flex-start;
height: 100%; overflow: scroll; scrollbar-width: thin;overflow-x: hidden;}
.tag{cursor: pointer; margin: 3px 5px 0; height:2rem;padding: 4px 6px 3px;}
.tag:active, .tag:hover, .icon:active, .icon:hover{box-shadow: -4px -2px 6px #FFFFFF, 4px 2px 10px rgba(136, 165, 191, 0.48);
    border-radius: 8px; }
.icon{width:2rem; margin: 6px 10px 0;}
.icon:active, .icon:hover{padding:3px;}
.displaytags, .perenos, .displayicons{font-size: 0.8em;display: flex; flex-wrap: wrap;max-width: 70%;}
.perenos{min-height: 30px;  white-space: normal;}
.displayicons img{width:22px;}
.displaytags div:not(:last-child):after, .perenos div:not(:last-child):after{     content: ', ';    margin-right: 3px;}
.displayicons div{margin-right: 3px; }
.displayword{display: flex; flex-direction: column;height: 100%;}
.txtarea{width: 100%;height: 70%; background: #ffffff; color: black;overflow: scroll; scrollbar-width: thin;overflow-x: hidden;}
.addtext{display: flex; justify-content: center}
.buttonaddtext{width:3.2rem;height: 3.2rem;margin: 3px 0;background: center no-repeat url('../../../images/icons/icon-yes.png') #E3EDF7;
     box-shadow: -4px -2px 6px #FFFFFF, 4px 2px 10px rgba(136, 165, 191, 0.48);background-size: 90%; border-radius: 30px;border: none;}
.buttonaddtext:hover, .buttonaddtext:active{box-shadow: inset -3px -3px 10px #FFFFFF, inset 3px 3px 10px rgba(136, 165, 191, 0.48);
    background-size: 70%;}
.titletxt{font-size: 0.75em;text-align: center; font-style: italic}

.blinker{ animation: blinker 2s step-start infinite; /* linear*/
}

@keyframes blinker {
    50% {      opacity: 1;   }
    70% {        opacity: 0;  }
}