pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.head{
    min-width: 30vw;
    background-color: #c2c2c2;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    /*flex-grow: 1;*/
    justify-content: flex-start;
    width:87%;
}
.body{
    margin-top: 5px;
    /*display: flex;*/
    /*flex-direction: column;*/
    width: 100%;
    min-height:50vh;
}
.bodyredact{
    min-height:30vh;display:flex;flex-direction: row;
}
.blockflex{
    display: flex;
    flex-direction: row;
    padding-bottom: 0.6rem;
}
.mainblock{width:86%; margin-left: 10px; display: flex;flex-direction: column;}
.markdelete{background-color: #ffe1d7;}
.sideblockedit{ opacity: 0.0; margin: 0 7px; border-left:2px solid #c2c2c2; width:14%}
.mainblock:hover{background-color: #e6e6e6;}
.mainblock:hover + .sideblockedit{opacity: 1.0;}
.sideblockedit:hover{opacity: 1.0;}
.mainblock h2, .mainblock h3, .mainblock h1{font-weight: normal}

