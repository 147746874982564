.Button{
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    color:#000;
    margin-right: 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;

}
.Button:hover{
   border: 1px solid #6c6c6c;
    box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.75);

}
.Button:active{
    box-shadow: inset 2px 1px 1px rgba(0,0,0,.3);

}
.Button:disabled {
    background: #ccc;
    color: #000;
    cursor: not-allowed;
}
.error{background: #ac2925;}
.success{background: #3e8f3e;}
.primary{background: #2b669a; color:#fff;}
.adminprimary{background: #817d87; color:#fff;   }