

.commentblock{padding: 0 5px;margin: 5px 0;}
.comment{display:flex;flex-direction:row; margin-bottom:0.7rem;}
.comment:hover {background-color: rgb(229, 229, 229);}
.comment:hover .vyvod {opacity: 1}
.admin{background-color: rgba(54, 204, 188, 0.11); border-radius: 12px;}
.markdelete,.markdelete:hover{background-color: rgba(229, 182, 182, 0.49)
}
.emptycomments{margin: 15px 0 0 10px;text-align: center;}
.otstupcomm{margin-left:1.5rem; margin-right:2px;}

.nametext{display:flex;flex-direction:column;padding-left:4px;width: 100%;}

.namedate{display: flex; justify-content:flex-start; align-items: center; margin-bottom: 3px;/*background-color: rgb(229, 229, 229);*/}
.namedate input[type="checkbox"]{margin:0 2px;}
.namecomm{margin:0 5px 0 0;font-size:0.8rem;}
.datecomm{margin:0 0 0 5px;font-size:0.8rem;}
.textreply{display:flex;flex-direction:column;margin-top:3px;}
.textcomm{white-space: pre-wrap;}
.textareacomm, .answertar{resize: none; font-size: 1rem; border-radius: 3px; border: 1px solid #242424; padding: 2px 3px;font: inherit; line-height: 1.4}
.replycomm{width:1.7rem; height:1.7rem;cursor: pointer; border-radius: 30%; margin-top:3px; margin-left: auto;;
    background: url('../../../images/icons/reply.svg') no-repeat ;
    background-size: cover;}

/*.replycomm{pointer-events: auto}
.nametext{pointer-events: none}
.nametext:hover {background-color: #d0dce3;}*/

.checkadm, .vyvod{margin:0 2px;}
.checkadm, .checkadm input, .vyvod button{cursor: pointer}
.vyvod{display: flex;flex-direction: column; opacity: 0; min-height: 100px; justify-content: space-around;}
.novyvod{color: #c7c7c7
}
.always{opacity: 1}

.class-true{
    background: url('../../../images/icons/icon-yes.png') no-repeat ;
    background-size: cover;
}
.class-false{
    background: url('../../../images/icons/icon-zero.png') no-repeat ;
    background-size: cover;
}

.answerblock{display:flex; margin-left: 10px;  }
.answertar{width:80%;}
.answerbutton{border-radius: 8px;margin-left: 10px;  cursor: pointer; color: var(--fontcolorselect); max-height: 100px;
    padding: 10px 20px; font-size: 0.9rem;   border: 2px solid var(--commentcolorfon);   background-color: var(--commentcolorfon);}
.addbutton:hover{box-shadow: 1px 1px 3px 0 rgba(50, 50, 50, 0.75);}

.emojiicon{width:30px; height: 30px; font-size: 1.4rem; cursor: pointer;margin-left: 10px;}
.emojiblock{z-index: 1000;
    position: absolute !important;
    right: -7px; height: 30vh;
    top: 30px;}
.hider{display: none;}