.Layout{
    height: 100vh;
    display: flex;
    flex-direction: row;
}
.leftpart, .rightpart, .rightpartcenter{
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.leftparthidden{display:none;}
.rightpartcenter{margin:auto;}
.Layout main{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.Layout .head{
    display: flex;
    flex-direction: row;
}
.Layout .error{
    display: block;
    padding-top: 10px;
    width:15% ;
    height:80px;
    font-size: 11px;
    color: #8f0f0e;
    overflow: auto
}
.Layout .article{
    margin: 10px 0 0 5px;
    display: flex;
    flex-direction: column;
    width:54vw;
}

.asider{
    margin-top: 15px;
}
