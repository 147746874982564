.overlay{display:none;position: fixed; align-items:center;  justify-content:center;
    top: 0; left: 0; width: 100%; height: 100%;
    background-color: black; z-index:1001;  opacity:.70;
    transition: .5s ease-in; }
.lightcontent{
    display:none;position: fixed; align-items:center;  justify-content:center;
    top: 0; left: 0; width: 100%; height: 100%;z-index:1002;
}
.overpicture{display: grid;  grid-template-columns: 20px  1fr 50px;   grid-template-rows:  1fr 40px 10px; align-items: end;
    max-width:1200px;  opacity:1; z-index: 1003; max-height:100vh; padding:7px;
    background: var(--mainfoncolor); box-shadow: -5px -2px 8px #AABDD1, 7px 3px 12px rgba(136, 165, 191, 0.58);border-radius: 16px;
}
.overpicture picture{max-width:100%; /*clip-path: inset(0% 100% 0 100% round 10px);*/ grid-column: 1 / -1;   grid-row: 1 / -1;line-height: 0;}
.overpicture picture img{max-height: 97vh;border-radius:10px;}

.overnote{position: relative;grid-column: 2 / -1; grid-row: 2/ 3; padding: 5px; width: min-content;  min-width: fit-content;
    background-image: linear-gradient(
        -90deg,
        hsla(0, 0%, 35.29%, 0) 0%,
        hsla(0, 0%, 34.53%, 0.034375) 16.36%,
        hsla(0, 0%, 32.42%, 0.125) 33.34%,
        hsla(0, 0%, 29.18%, 0.253125) 50.1%,
        hsla(0, 0%, 24.96%, 0.3) 65.75%,
        hsla(0, 0%, 19.85%, 0.446875) 79.43%,
        hsla(0, 0%, 13.95%, 0.575) 90.28%,
        hsla(0, 0%, 7.32%, 0.665625) 97.43%,
        hsla(0, 0%, 0%, 0.6) 100%
);

    color: white; text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.73), -3px -3px 8px rgba(0, 0, 0, 0.73);}
.overclose{grid-column: 3 / 4; grid-row: 1 / 1; position: relative; align-self: start; justify-self: end; width: 40px;height: 40px;
cursor: pointer}
.overclose2:hover{background-color: rgba(150, 150, 150, 0.73);}
/*.closepicture g:hover circle, g:hover path {   transform: scale(1.2) rotate(45deg) ; }*/
.modalshow{ overflow: hidden;}