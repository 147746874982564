.dirtbl{display: flex; flex-direction: column; font-size: 0.9rem;}
.dirstr{display: inline-flex;margin:2px 0; align-items: center;}
.dirstr:hover{background-color: #e1e1e1;}
.dir{ flex:2 1 0;margin:0 10px;cursor: pointer; color: #3c3c4d; font-weight:600; white-space: nowrap;
    overflow: hidden;     text-overflow: ellipsis; padding-right: 15px;}
.dirtype{flex:0.5 1 0; font-size: 0.8em; font-style: italic;}
.file, .currentdir{flex:2 1 0;margin:0 5px; white-space: nowrap;    overflow: hidden;     text-overflow: ellipsis;}
.filetype{flex:0.3 1 0; font-size: 0.8em; font-style: italic;padding-left: 5px;text-align: center;display: inline-block;}
.selectfile{flex:0.3 1 0;cursor: pointer;display: inline-flex;}
.selectfile button.increasefon{background-size: contain; }

.deletebutton{cursor: pointer;}
.blockdelete, .blockcreate{display: flex; background-color: #e5b8b7;}
.blockcreate{background-color: #e4e5e0; align-items: center;}

.textdel, .inputfolder{flex:2 1 0; margin-right: 5px;font-size: 0.8em;}
.inputfolder{margin-left: 5px; height: 90%;}
.blockdelete button{ margin-right: auto;}

.header{display: flex; justify-content: space-between; align-items: center; margin-bottom: 2px; border-bottom: 1px solid #474747
}
.currentdir{margin-left: 5px;color: #676767; font-size: 0.8em;}
.buttondir{flex:0.5 1 0;display: inline-flex; margin-bottom:4px;}
.buttondir button{cursor:pointer; }
.inputfile{     outline:0;opacity:0;pointer-events:none;user-select:none;}
.labelfile{
    margin:0 2px; border:none; background: url('../../../images/icons/icons-photo.png') no-repeat ;
    background-size: cover;  width:23px; height:23px;
    display:block;   cursor:pointer;
}
.labelfile:hover{border: 1px solid #6c6c6c;margin:0;padding:0;
    box-shadow: 1px 1px 3px 0 rgba(50, 50, 50, 0.75);}