.footer{background: var(--mainfoncolor);box-shadow: 0 -4px 8px rgba(136, 165, 191, 0.42);
margin:5px 0 0 0; display: flex; font-size: 0.7rem;}
.left{width:55%;display: flex;flex-direction: row;}
.left a{margin: 5px 3px 3px 5px;text-decoration: none;font-size: 0.8rem;color:var(--maintextcolor);
    display: inline-block; height: 2rem;}
.left a:visited{color:var(--maintextcolor);}
.right{margin: 3px 6px 7px 0; text-align: right;width:45%}

@media (max-width:660px) {
    .left a { margin: 5px 13px 7px 5px; padding: 4px;   }
    .left{width:35%;}
    .right{width: 65%}
}

