.sflex{font-size:0.8rem; padding:4px 6px;}
.advert{ border: #b5d1ef solid 1px; border-radius: 8px; }
.sheader{ margin-bottom: 6px; text-decoration:underline dotted var(--fontcolorselect);text-underline-offset:4px;}
.sheader a{text-decoration: none;color: var(--fontcolorselect);opacity: 0.8; font-size:0.9rem;  }
.sblock{  padding: 2px 4px; margin-bottom: 0.7rem;}
.stext{overflow: hidden;height: 3rem;position: relative;text-overflow: ellipsis; opacity: 0.8; margin-left: 3rem;
    display: -webkit-box;-webkit-line-clamp: 3;    -webkit-box-orient: vertical;}

@media (max-width:900px){
    .stext{height: 2rem;margin-left: 1.5rem;-webkit-line-clamp: 2;}
}

@media (max-width:800px) {
    .sflex{ display: grid; grid-auto-flow: column;/* grid-auto-columns: 1fr;*/ grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
        grid-gap:0.6rem;padding:0 4px;}
    .sblock{box-shadow: -3px -3px 7px #FFFFFF, 3px 3px 3px rgba(136, 165, 191, 0.48);
        border-radius: 8px; padding: 1px 5px;text-align: left;}
    .stext{margin-left: 0;}
    .advert{border:#b5d1ef solid 2px; background: rgba(181, 209, 239, 0.51)
    }
}
@media (max-width:600px) {
    .sblock{text-align: center; display: flex;padding: 5px 0;}
    .sheader{margin: auto;text-decoration:none;padding: 0 5px;}
    .stext{display: none;}
}
@media (max-width:500px) {
    .sflex{display: flex; padding: 0 4px;
        flex-direction: row; flex-wrap: wrap;
        justify-content: space-around;}
    .sblock{width: 46%; min-height: 3rem}
}
/*
.stext:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 1.2em;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--mainfoncolor) 100%);
    pointer-events: none;
}*/
