.draftdiv {border: 0px solid #959595; min-height:150px; margin: 0 10px; width:97%}
.draftpanel{ display:  flex; flex-direction: row; justify-content: flex-start;padding:4px 0 4px; background-color: #dbdbdb;margin:-1px 0 0px;}

.draftmain{border: 1px solid #959595; width:87%;}
.otstupbutton{width:80%;margin:2px 0;}
.selectblock{margin-top: 3px; display: inline-block;}

/*.divederdiv{height:60%;display: inline-block; }*/
.divider {margin:3px 5px;  border-left: 1px solid #a6a6a6;padding: 0px 0 9px;}

.linkblock{margin:5px 2px 1px 3px;display: flex;justify-content: flex-start;}
/*.linkblock2{margin:3px;display: flex; justify-content:   flex-start ; background-color: #ffffff;}*/
.linkblock span{font-size: smaller;margin-top: 2px; }
.linkvalue{margin-top: 0px; width:80%;}
.linkouter{ width:20px; height:20px;    }
.linkblock input[type="checkbox"]{margin:0;}