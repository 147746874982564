.mainblock{width:200px; height: 44px;border:1px solid #676767; border-radius: 10px;  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
display: grid; grid-template-rows: 100%; }
.mainblock div{grid-column:1/1;grid-row: 1/1;}
.inner{height:44px;background-color: rgba(44, 64, 118, 0.6);border-radius: 8px; transition: 0.1s ease-out}
.inner.active{background-color: rgba(30, 238, 15, 0.6);}
.innertext{align-self: center;justify-self: center; cursor: pointer;
    width:100%; height:100%; display: flex; align-items: center;justify-content: center;}
.innertext span{font-size: 1rem; color: black;}


.progress{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height:44px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
progress[value]{
    border-radius: 10px;
    appearance: none;
    border: none;
}
progress[value]::-moz-progress-bar {
    border-radius: 10px;
    appearance: none;
    border: none;
    margin: 0 0 1.5em;
}
/*
progress[value]::-webkit-progress-value:after{
    border-radius: 10px;
}
*/



