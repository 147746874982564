.formelement{    font-size: 16px;}
.labelform { font-size: 0.9em; margin-left: 3px}
.textform, .textforminfo{font-size: 0.8em; margin: 0 3px; height:1.4em; border: none; border-radius: 5px; background-color: #f1f1f1;
    color: #333333
}
.textform {width:90%; }
.textforminfo{width:40%; }
.infoformat{margin-left: 5px; font-style: italic; font-size: 0.9em;}
.elementurl{height:5em; max-width: 90%; min-width: 90%;max-height: 5em;}

.buttonelement {display: flex; justify-content: right;}
.buttonelement button.buttonel{width:30px; height: 30px;}

.buttonaddfield{min-width:30px; height: 30px;  margin:0 4px; background-color: #f3f3f3;
    border:0px solid #6c6c6c;}
.buttonaddfield:hover{ border: 1px solid #6c6c6c;  box-shadow: 1px 1px 1px 0 rgba(50, 50, 50, 0.75);}
.buttonfield {display: flex; justify-content: space-between; width:95%;}
.select{width:23%; margin: 4px 0;}
.labelselect { display: inline-flex; width:80%; font-size: 0.9em; margin-left: 3px}
.labelselect .label{width:23%}
.anons{height:5em; max-width: 90%; min-width: 90%;max-height: 10em;}
.color{padding:0 5px;display:flex; justify-content: center;align-items: center;}
.warning{width:90%; text-align: center; display: flex;flex-direction: row;align-items: center;justify-content: left;}
.fileinput{ outline:0;opacity:0;pointer-events:none;user-select:none}
.fonbutton{margin:-1px 0 0px;}
.labelfile{    margin:1px 10px; border:none;    width:30px; height:30px;
    display:block;    cursor:pointer;text-align:center;
    background: url('../../../images/icons/lupa.png') no-repeat ;
    background-size: cover;
}
.flexbox{display: flex; flex-direction: row; justify-content: flex-start;align-items: center;}
.iconelement{max-width: 40px; margin: 5px 5px; display:grid; grid-template-rows: repeat(3, 1fr) ; max-height: 57px; justify-items: center;}
.iconelement img{width: 30px;}
.iconelement div, .iconelement img{margin: 0;grid-column:1/1;grid-row:2/2;}
.iconelement div{background-color: rgba(139, 92, 92, 0.96); font-size: 0.8rem; grid-row:2/2; opacity: 0; transition: opacity 0.2s ease-in-out;
    border-radius: 5px;  padding: 5px;
}
.iconelement div:hover{opacity: 1; cursor: pointer; text-shadow: 0 0 2px #8B5C5C; color: white;}
.icongroup{display: flex;width: 95%; border-bottom:1px solid #2f314d; margin: 0 5px; flex-wrap: wrap}