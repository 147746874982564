.menup{
 /*display: block;*/
    margin:5px 5px 0px;
    padding:3px 10px;
    cursor: pointer;
    color: #2b669a;
    font-weight: 400;
    width: max-content;
}
.menup:hover{
    color: #39567b; font-weight: bold;
}
.breadcrumbs{
    margin:0 5px;
    padding:3px 10px;
}
.breadcrumbs a{
    margin: 0 1px;
    text-decoration-line: none ;
    color: #2b669a;
}

.breadcrumbs a:before {
    content: " / ";
}
.flexmenu{
    /*width:40vw;*/
    display: flex; justify-content: space-between;
    padding:3px 10px;
}
 .left{
     width:83%;
}
 .right{

}
 .inlineall{display:inline-block; width: 100%; margin-bottom: 5px;}

.rasdelname{ font-size: medium;color: #5e5e5e;}
.active{
    cursor: pointer;  color: rgb(160,160,200);
}