.uploader form{
 display: flex; justify-content: space-between;
}
.fileinput{
     outline:0;opacity:0;pointer-events:none;user-select:none
}
.label, .imgmain{
    margin:1px 10px; border:none;
    width:35px; height:35px;
    display:block;
    cursor:pointer;text-align:center;
}
.label{
    background: url('../../../images/icons/icons-photo.png') no-repeat ;
    background-size: cover;
}
.submitbutton {
    background: url('../../../images/icons/icons-save.png') no-repeat;
    background-size: cover;
}
.cropperoff {
    background: url('../../../images/icons/switchoff.png') no-repeat;
    background-size: cover;
}
.cropperon {
    background: url('../../../images/icons/switchon.png') no-repeat;
    background-size: cover;
}
.editimage {
    background: url('../../../images/icons/icons-edit.png') no-repeat;
    background-size: cover;
}
.deleteimage {
    background: url('../../../images/icons/icons-delete.png') no-repeat;
    background-size: cover;
}

.label:hover, .imgmain:hover{
    border: 1px solid #6c6c6c;
    box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.75);
}
.note{
 width:90%; margin: 5px 0;
}
.imgpreview{
    display: flex; justify-content: center; flex-direction: column; align-items: center;
}
.imgpreview img{
max-width:100%;
 /*//   padding:3px;
 //max-height: 350px;*/
}
.previewtext{
    width:100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #e1e1e1;
}
.previewtext div{
    margin-left: auto;
    margin-right: auto;}

.imgdone{ margin-top: 5px;

}
.imgdone img{ margin-left: auto;

}
.imgnote{padding:0 2px;}
.imgnote span{font-size: smaller; font-style: italic;}

.perehodpicture{
    display: flex; justify-content: space-between; flex-direction: row; width: 100%;
}
.perehodleft, .perehodright{
    width: 40px; height:30px; margin:2px; border:none; cursor:pointer;
}
.perehodleft {
    background: url('../../../images/icons/scrolleft.png') no-repeat;
    background-size: contain ;
}
.perehodright {
    background: url('../../../images/icons/scrolright.png') no-repeat;
    background-size: contain;
}