:root{
    --fontcolorselect: #4f4b52;
    --fontsixeforartickle:1.4rem;
}
.articlebody{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
background-color: var(--mainfoncolor);
}
.articleblock{margin-left: 10px;}

.header{margin:2px;color: var(--fontcolorselect); cursor: pointer; font-size: var(--fontsixeforartickle); }
.header a{text-decoration: none; color: var(--fontcolorselect);}
.headercenter, .headercenter a{ margin-right: auto;margin-left: 1rem; text-decoration: none; margin-bottom: 5px;
    font-size: var(--fontsixeforartickle);color: var(--fontcolorselect);font-weight: normal}
.headerblock, .headerblockdown{display:flex;flex-direction:row;width: 100%;justify-content: space-between}
.headerblockdown .iconartinblock{margin-left: auto}
.headertext{flex:2 1 auto; padding: 0 4px;}
.headerpict{padding:10px;  position:relative; box-shadow: -5px -2px 5px #AABDD1, 5px 3px 5px rgba(136, 165, 191, 0.58);
    border-radius: 8px; margin: 3px 7px 0 0; flex:2 0 45%; align-self: flex-start;
    min-width: 30%; max-width: fit-content;}
.headerpict img{margin:0;max-width: 100%;}
.headericonart{flex:0.5 0.1 auto;display: flex; flex-direction: column;align-items: flex-end; margin-left: 1rem;}
.headericonart img{margin:0;max-width: 1.6em;}
.headericonras img{margin:0;max-width: 1.3em;}

.article, .articledown {width: 100%;  flex: 1 1 auto; /*fill all block*/ padding: 10px;
    display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;
    background: var(--mainfoncolor);
    box-shadow: -3px -3px 3px #FFFFFF, 3px 3px 3px rgba(85, 100, 121, 0.48);
    border-radius: 8px; margin-bottom: 0.5rem}
.articledown{
    box-shadow: inset -3px -3px 7px #FFFFFF, inset 2px 2px 5px rgba(85, 100, 121, 0.38);
    border-radius: 16px;
}
.morearticle{display: flex;width:100%; height:3rem;justify-content: center; margin-right: 2rem;}
.morearticle button{border: none;  width:100%;  background: var(--mainfoncolor); cursor: pointer;
    box-shadow: -3px -3px 3px #FFFFFF, 3px 3px 7px rgba(85, 100, 121, 0.48); color:var(--maintextcolor);;
    border-radius: 4px; margin-bottom: 0.5rem;font-size: 1rem; }
.morearticle button a{text-decoration: none;color:var(--maintextcolor);}
.outart{margin:0.5rem 0 0 1rem; cursor: pointer; color: var(--fontcolorselect) ;}
.outblock{display:flex; flex-direction: row;justify-content:  space-between; width: 100%;}

.iconblock{display: flex;flex-direction: column; justify-content:  space-between;margin-left:10px;}
.iconartinblock{display: flex;flex-direction: row;}
.iconarasdinblock{}

.tagblock{display: flex;margin:3px 5px 5px 7px; font-size: 0.9rem; font-style: italic; }
.tagblock a, .tagblock a:hover{color:var(--colortag); text-decoration: none; }
.tagblock a:not(:last-child):after{     content: ' • ';    margin:0 7px;}
.tagblock span{font-style: normal; margin-right: 5px;}

.datecreated{display: flex; justify-content: flex-end; font-size: 0.7rem;margin: 2px 2px ;}
.imagebord  { display: grid;   grid-template-columns: 20px  1fr 20px;   grid-template-rows:  1fr 40px 10px; align-items: end;
    padding:10px;  position:relative; align-self: center;
    background: var(--mainfoncolor); box-shadow: -5px -2px 8px #AABDD1, 7px 3px 12px rgba(136, 165, 191, 0.58);
    border-radius: 16px;}

.commcount{margin: 10px 10px 0; text-align: right; }
.commcount a, .commcount a:hover{color:var(--colortag);}

@media (max-width:660px){
    .headerblock{flex-direction:column;}
    .iconblock{order: -1;align-items: flex-end; }
}