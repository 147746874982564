.container {
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto 50%;
}

.pagepreloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
}

.preloaderitem {
    content: "";
    display: block;
    position: absolute;
    background-color: #c5e1ff;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    z-index: 999;
}

.preloaderitem1 {
    animation-name: orbit-top-left;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-delay: 0s;
}

.preloaderitem2 {
    animation-name: orbit-top-right;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-delay: 0s;
}

.preloaderitem3 {
    animation-name: orbit-down-left;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-delay: 0s;
}

.preloaderitem4 {
    animation-name: orbit-down-right;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-delay: 0s;
}

@keyframes orbit-top-left {
    0% {
        transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    }
    20% {
        transform: rotate3d(0, 0, 1, 0deg) translate3d(150%, 150%, 0);
    }
    80% {
        transform: rotate3d(0, 0, 1, 360deg) translate3d(150%, 150%, 0);
    }
    100% {
        transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0);
    }
}

@keyframes orbit-top-right {
    0% {
        transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    }
    20% {
        transform: rotate3d(0, 0, 1, 0deg) translate3d(150%, -150%, 0);
    }
    80% {
        transform: rotate3d(0, 0, 1, 360deg) translate3d(150%, -150%, 0);
    }
    100% {
        transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0);
    }
}

@keyframes orbit-down-left {
    0% {
        transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    }
    20% {
        transform: rotate3d(0, 0, 1, 0deg) translate3d(-150%, -150%, 0);
    }
    80% {
        transform: rotate3d(0, 0, 1, 360deg) translate3d(-150%, -150%, 0);
    }
    100% {
        transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0);
    }
}

@keyframes orbit-down-right {
    0% {
        transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    }
    20% {
        transform: rotate3d(0, 0, 1, 0deg) translate3d(-150%, 150%, 0);
    }
    80% {
        transform: rotate3d(0, 0, 1, 360deg) translate3d(-150%, 150%, 0);
    }
    100% {
        transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0);
    }
}
/*----------------------------------------*/


.wrapper {
    --bigcircleload:70px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 70px;
    height: 70px;
    margin: 2rem 50% 0;
}
.maincircle {
    width: calc(var(--bigcircleload) - 1px);
    height: calc(var(--bigcircleload) - 1px);
    border:4px solid var(--calcsecond);
    border-top: 4px solid transparent  ;
    border-right:4px solid transparent;
    position:relative;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotate 2s infinite;
}
/*.maincircle:before {
    width: 100%;
    height: 100%;
    position:absolute;
    content:'';
    border:4px solid transparent;
    border-right:4px solid blue;
    transform: rotate(40deg);
    border-radius:50%;
}*/
.greencircle {
    animation: rotate 2s infinite .4s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--bigcircleload) - 30%);
    height: calc(var(--bigcircleload) - 30%);
    border:4px solid var(--calcsecond);;
    border-top:4px solid transparent;
    border-right:4px solid transparent;
    transform: rotate(-20deg);
    border-radius:50%;
    position:relative;
}
/*.greencircle:before {
    content:'';
    width: 100%;
    height: 100%;
    border-radius:50%;
    position:absolute;
    border:4px solid transparent;
    border-right:4px solid blue;
    transform: rotate(60deg);
}*/
.browncircle {
    animation: rotate 2s infinite .6s;
    width: calc(var(--bigcircleload) - 80%);
    height: calc(var(--bigcircleload) - 80%);
    border:4px solid var(--calcsecond);;
    border-top:4px solid transparent;
    border-right:4px solid transparent;
    transform: rotate(-20deg);
    border-radius:50%;
}
@keyframes rotate {
    from {}
    to {
        transform: rotate(360deg);
    }
}