.Inputfield{ }
.Inputfield.invalid {    background-color: #fae0dc;}

.class-true, .class-false{
    width:25px; height: 25px;
    margin-left: auto;
    margin-right: auto;
}
.class-true{
    background: url('../../../images/icons/icon-yes.png') no-repeat ;
    background-size: cover;
}
.class-false{
    background: url('../../../images/icons/icon-zero.png') no-repeat ;
    background-size: cover;
}

.disable{
    width:25px; height: 25px;
    margin-left: auto;
    margin-right: auto;
    background: url('../../../images/icons/icons-inactive.png') no-repeat ;
    background-size: cover;
}

input[type="checkbox"] {
    appearance: none;
    display: inline-block;
    width:25px; height: 25px;
    outline: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
}
.class-check{

    background: url('../../../images/icons/icons-checked.png') no-repeat ;
    background-size: cover;
    cursor: pointer;
}
.class-nocheck{

    background: url('../../../images/icons/icons-nochecked.png') no-repeat ;
    background-size: cover;
    cursor: pointer;
}
.countelem, .urlelem, .empter,.options, .password, .iconelem, .commelem{ width:50%; cursor: pointer;margin-left: auto; color: black; min-height: 1em;
    margin-right: auto; border: 0px solid #c1b9b9;
    box-shadow: 2px 2px 2px rgba(122,122,122,0.5);
}
.iconelem{margin-left: 5px;}
.iconelem img{height: 25px;}
.password{width:30%; height: 1.3em;}
.options,.commelem{width:60%; height: 1.3em;}
.urlelem, .empter, .iconelem{width:100%;display: flex;text-align: left;white-space: nowrap; overflow: hidden; justify-content: right; padding:2px 0;}
.urlelem span, .empter span{margin-left: 10px;}
.active{ background-color: #636363;color: white;}
.iconelem{align-items: center; gap:5%; justify-content: space-between;padding: 0 7px 0 0; font-size: 0.9rem; max-width: 22vw;min-height: 1.2rem;}
.iconvisual{  cursor: pointer}
.iconvisual img{width:29px; margin: 0;}
.border{border: 1px solid #82c5ff;height:100%;min-height: 1em;}
.postfix{font-size: 0.6rem;}