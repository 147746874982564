.blockaddinnerlink{width:87%;border:2px solid #c2c2c2;}
.buttonhead{background-color: #dbdbdb;margin:-1px 0 1px; display: flex; flex-direction: row;}
.buttonhead .changesize{margin-bottom:2px; }

.dirtbl{display: flex; flex-direction: column; font-size: 0.9rem;}
.dirstr{display: inline-flex;margin:2px 0; align-items: center;}
.dirstr:hover{background-color: #e1e1e1;}
.dir{ flex:1 1 0;margin:0 10px;cursor: pointer; color: #3c3c4d; font-weight:600; white-space: nowrap;
    overflow: hidden;     text-overflow: ellipsis; padding-right: 15px;}
.dirtype{flex:1 1 0; font-size: 0.8em; font-style: italic;}
.file, .currentdir{flex:1 1 0;margin:0 5px; white-space: nowrap;    overflow: hidden;     text-overflow: ellipsis;}
.currentdir{margin:2px 5px;cursor: pointer; color: #7d7d7d}
.filetype{flex:1 1 0; font-size: 0.8em; font-style: italic;padding-left: 5px;text-align: center;display: inline-block;}
.selectfile{flex:0.5 1 0;cursor: pointer;display: inline-flex;}
.selectfile button.increasefon{background-size: contain; }
.video{display: inline;width: 60%;}
.video input{margin: 2px 0 0 10px; width: 96%; }