.buttonrow{
    width:30px; height: 30px;
    margin:0 2px;
    border:none;
}
.buttonrow:hover{
    border: 1px solid #6c6c6c;
    box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.75);

}

.saverecord{
    background: url('../../../images/icons/icons-save.png') no-repeat ;
    background-size: cover;
}
.editrecord{
    background: url('../../../images/icons/icons-edit.png') no-repeat ;
    background-size: cover;
}
.deleterecord{
    background: url('../../../images/icons/icons-delete.png') no-repeat ;
    background-size: cover;
}
.markdeleterecord {
    background: url('../../../images/icons/icons-markdelete.png') no-repeat;
    background-size: cover;
}
.canceledit{
    background: url('../../../images/icons/icons-cancel.png') no-repeat ;
    background-size: cover;
}